import { Autocomplete, InputBase, styled, TextField, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import { StyledInputBase } from "../appBar/SearchBox"
import PropTypes from 'prop-types'
import { APP_ID, COMPANY_NAME, CONTRACT_GROUP, CONTRACT_MANAGER, NAME, PK, REGIONAL_GROUP, REGIONAL_MANAGER, ROLE, SK } from "../../DynamoDbConstants"
import { fetchAllAppsKeysOnly, fetchAllSites } from "../../services/dynamoDBservice"
import { matchSorter } from "match-sorter"
import { useNavigate } from "react-router-dom"

const test = [
  '1', '2', '3', '4', '5', '6', '7', '8',
  '11', '22', '31', '14', '54', '26', '7', '8'
]

export default function SearchBarAutoComplete (props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null)

  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: 
    [
      item => item[APP_ID] ? item[APP_ID] : item[NAME],
      item => item[APP_ID] ? item[APP_ID] : item[COMPANY_NAME]]})
  const [items, setItems] = useState([{ [APP_ID]: '' , [COMPANY_NAME]: ''}])

  useEffect(() => {
    if (open) {
      //console.log(items)
      if (items.length < 2) {
        fetchAllAppsKeysOnly(props.user[PK])
          .then(res => {
            setItems((options) => [...options, ...res.Items])
          })
          .catch(err => {
            console.log(err)
          })
          fetchAllSites(props.user[PK])
            .then(res => {
              switch (props.user[ROLE]) {
                case REGIONAL_MANAGER:
                  setItems((options) => [...options, ...res.Items.filter(site => site[REGIONAL_GROUP] === props.user[REGIONAL_GROUP])])
                  break;
                case CONTRACT_MANAGER:
                  setItems((options) => [...options, ...res.Items.filter(site => site[CONTRACT_GROUP] === props.user[CONTRACT_GROUP])])
                  break;
                default:
                  setItems((options) => [...options, ...res.Items])
              }
            })
            .catch(err => {
              console.log(err)
            })
      }
    }
    
  }, [open])

  const onOptionClicked = (option) => {
    if (option && option[APP_ID]) {
      // navigate to appliance view
      navigate(`/appliance-home/view-appliance/${option[APP_ID]}`, { state: { [NAME]: option[APP_ID] } })
    } else if (option) {
      // navigate to site view
      // creates a full site name out of company name and optional site name
      var siteName = option[NAME] ? `${option[COMPANY_NAME]} ${option[NAME]}` : option[COMPANY_NAME]
      siteName = siteName.replaceAll('/', ' ')
      console.log('navigating', siteName.toLowerCase())
      // splits the full site name into individual words, ready for displaying in the domain name
      const elements = siteName.toLowerCase().split(' ').filter(element => element)
      console.log(elements)
      if (elements.length > 1) {
        // navigates to the site view, displaying the full site name joined by dashes
        navigate(`/appliance-home/site/${elements.join('-')}`, { state: option })
        return
      }
      // if the full site name was one word, no need to join it and can just navigate
      navigate(`/appliance-home/site/${siteName.toLowerCase().trim()}`, { state: option })
    }

  }

  const createOptionLabel = (option) => {
    if (option && option[APP_ID]) {
      return option[APP_ID]
    } else if (option && option[COMPANY_NAME]) {
      return option[NAME] ? `${option[COMPANY_NAME]}, ${option[NAME]}` : option[COMPANY_NAME]
    } else {
      return ''
    }
  }

  return (
    <Autocomplete 
      id='search-bar-auto-complete'
      blurOnSelect
      options={items}
      getOptionLabel={option => createOptionLabel(option)}
      filterOptions={filterOptions}
      open={open}
      onOpen={() => {
        if (inputValue) {
          setOpen(true)
        }
      }}
      onChange={(e, value) => onOptionClicked(value)}
      onClose={() => setOpen(false)}
      value={value}
      inputValue={inputValue}
      onInputChange={(e, value, reason) => {
      setInputValue(value);
      // only open when inputValue is not empty after the user typed something
      if (!value) {
        setOpen(false);
      } else {
        setOpen(true)
      }
    }}
      renderInput={(params) => 
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          aria-label="Search..."
          placeholder="Search..."
          sx={{
            color: 'inherit',
            '& .MuiInputBase-input': {
              padding: theme.spacing(1, 1, 1, 0),
              paddingLeft: `calc(1em + ${theme.spacing(4)})`,
              transition: theme.transitions.create('width'),
              width: '100%',
              [theme.breakpoints.up('md')]: {
                width: '20ch',
              },
          },
          }}
        />
      }
    />
  )
}

SearchBarAutoComplete.propTypes = {
  user: PropTypes.object
}
