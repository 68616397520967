
export const DEV = {
  domain: 'test123.com',
  config: {
    identityPoolId: "eu-west-2:9279622c-6381-48c1-900f-38e8ed2e2404",
    region: 'eu-west-2',
    userPoolId: "eu-west-2_xGH8EhAxS",
    userPoolWebClientId: "587mtb1itgvpq8oo6dgf1vqh7k",
    mandatorySignIn: true,
    cookieStorage: {
      domain: 'test123.com',
      path: '/',
      expires: 365,
      secure: true
    }
  }
}

export const RELEASE = {
  domain: 'audit-app.co.uk',
  config: {
    identityPoolId: "eu-west-2:9279622c-6381-48c1-900f-38e8ed2e2404",
    region: 'eu-west-2',
    userPoolId: "eu-west-2_xGH8EhAxS",
    userPoolWebClientId: "587mtb1itgvpq8oo6dgf1vqh7k",
    mandatorySignIn: true,
    cookieStorage: {
      domain: 'audit-app.co.uk',
      path: '/',
      expires: 365,
      secure: true
    }
  }
}

export const RELEASE_RYEFORDS = {
  domain: 'pat.ryefords.com',
  config: {
    identityPoolId: "eu-west-2:9279622c-6381-48c1-900f-38e8ed2e2404",
    region: 'eu-west-2',
    userPoolId: "eu-west-2_xGH8EhAxS",
    userPoolWebClientId: "587mtb1itgvpq8oo6dgf1vqh7k",
    mandatorySignIn: true,
    cookieStorage: {
      domain: 'pat.ryefords.com',
      path: '/',
      expires: 365,
      secure: true
    }
  }
}

export const AMPLIFY_TEST = {
  domain: 'dab0129e9hwo2.amplifyapp.com',
  amplify: true,
  config: {
    identityPoolId: "eu-west-2:9279622c-6381-48c1-900f-38e8ed2e2404",
    region: 'eu-west-2',
    userPoolId: "eu-west-2_xGH8EhAxS",
    userPoolWebClientId: "587mtb1itgvpq8oo6dgf1vqh7k",
    mandatorySignIn: true,
    cookieStorage: {
      domain: 'dab0129e9hwo2.amplifyapp.com',
      path: '/',
      expires: 365,
      secure: true
    }
  }
}