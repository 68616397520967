import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router';
import { useLocation, useOutletContext } from "react-router-dom";
import { AccountCircle, ArrowBack, Event, KeyboardArrowUp } from '@mui/icons-material';
import PropTypes from 'prop-types'
import { ADMINSTRATOR, CONTRACT_MANAGER, DEVELOPER, FIRST_NAME, IS_ADMIN, IS_DEVELOPER, NAME, PAT_TESTER, PK, REGIONAL_MANAGER, ROLE, SK, USERNAME } from '../../DynamoDbConstants';
import { Fab, Fade, ThemeProvider, useScrollTrigger } from '@mui/material';
import { Search, SearchIconWrapper, StyledInputBase } from './SearchBox';
import ApplianceAutoComplete from '../autoCompletes/ApplianceAutoComplete';
import SearchBarAutoComplete from '../autoCompletes/SearchBarAutoComplete';
import { fetchUsers } from '../../services/dynamoDBservice';
import { getRoleText } from '../../services/dataSortingService';
import {ReactComponent as AtlasLogo} from '../../assets/atlas_logo.svg'
import AppsIcon from '@mui/icons-material/Apps';
import { THEME } from '../../themes/CustomThemes';

export const ScrollTop = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();
  const handleClick = (event) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // const anchor = (event.target.ownerDocument || document).querySelector(
    //   '#back-to-top-anchor',
    // );

    // if (anchor) {
    //   anchor.scrollIntoView({
    //     block: 'end',
    //   });
    // }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element
}


const ResponsiveAppBar = (props) => {
  const [settings, setSettings] = React.useState([])

  const [pages, setPages] = React.useState(['Home', 'Sign Out'])
  const [applications, setApplications] = React.useState(['Company Feed', 'Appliances'])
  const [streamPages, setStreamPages] = React.useState(['Home', 'Sign Out'])

  React.useEffect(() => {
    if (props.user) {
      if (props.user && (props.user[ROLE] === ADMINSTRATOR || props.user[ROLE] === REGIONAL_MANAGER || props.user[ROLE] === DEVELOPER)) {
        setPages(['Home', 'Sites', 'Companies', 'Users', 'Sign Out'])
      } else {
        setPages(['Home', 'Sign Out'])
      }
    }
  }, [props.user])

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElApp, setAnchorElApp] = React.useState(null)
  let navigate = useNavigate()
  let location = useLocation()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    fetchUsers(props.user[PK])
      .then(res => {
        if (props.user[IS_DEVELOPER]) {
          console.log(res.Items)
          setSettings(res.Items)
        } else if (props.user[IS_ADMIN]) {
          console.log(res.Items)
          setSettings(res.Items.filter(i => i[ROLE] !== DEVELOPER))
        }

      })
      .catch(err => {
        console.log(err)
      })
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenAppMenu = (event) => {
    setAnchorElApp(event.currentTarget)
  }

  const navigateToPage = (event) => {

    if (event === 'Sign Out') {
      props.signOut()
      return
    }
    console.log('navigating', event.toLowerCase())
    if (event === 'Home') {
      if (checkLocation('/home')) {
        navigate('/home')
      } else {
        navigate('/appliance-home')
      }
      
    } else {
      const elements = event.toLowerCase().split(' ')
      if (elements.length > 1) {
        navigate(`/appliance-home/${elements.join('-')}`)
        setAnchorElNav(null);
        return
      }
      navigate(`/appliance-home/${event.toLowerCase()}`)
    }
    setAnchorElNav(null);
  }

  const navigateToApp = (event) => {
    switch(event) {
      case 'Appliances':
        navigate('/appliance-home')
      break;
      case 'Company Feed':
        navigate('/home')
      break;
      default:
    }
    setAnchorElApp(null)
  }

  const onUserTypeClicked = (value) => {
    console.log(value)
    props.setRole(value)
    setAnchorElUser(null);
  }

  const handleCloseNavMenu = (event) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const handleCloseAppMenu = () => {
    setAnchorElApp(null)
  }

  const onBackClicked = () => {
    console.log(location.pathname)
    navigate(-1)
  }

  const checkLocation = (check) => {
    return location.pathname.startsWith(check)
  }

  return (
    // color of icon is #da0134
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>

            
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
              
              <IconButton
                sx={{ visibility: (location.pathname !== '/appliance-home' && location.pathname !== '/home' && location.pathname !== '/company-feed') ? 'visible' : 'hidden' }}
                aria-label="back"
                aria-controls="menu-appbar"
                onClick={onBackClicked}
                color="inherit"
              >
              
                <ArrowBack />
              </IconButton>
            </Box>
            
            {(props.user[IS_DEVELOPER] === true || props.user[IS_ADMIN]) &&
              <Box>
                <Tooltip title="Open settings">
                  <IconButton
                    size='large'
                    onClick={handleOpenUserMenu}
                    color="inherit">
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting[SK]} value={setting} onClick={(e) => onUserTypeClicked(setting)}>
                      <Typography textAlign="center">{`${setting[FIRST_NAME]} ${setting[NAME]} ${getRoleText(setting[ROLE])}`}</Typography>
                    </MenuItem>
                  ))}
                </Menu>

              </Box>
            }
            <ThemeProvider theme={THEME}>
            <Box sx={{display: { mobile: 'none', tablet: 'flex'}}}>
            <AtlasLogo fill='white' style={{ maxHeight: '34px', alignSelf: 'center' }}/>
            </Box>
            </ThemeProvider>
            
            
          </Box>
          

          {checkLocation('/appliance-home') &&
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <SearchBarAutoComplete user={props.user} />
            </Search>
          }
          {checkLocation('/appliance-home') &&
            <Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    value={page}
                    onClick={e => navigateToPage(page)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="navigation"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ p: 0 }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} value={page} onClick={e => navigateToPage(page)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              
              {/* <img alt='atlas_logo' src={atlas_logo} style={{ fill: 'white', stroke: 'white' }}/> */}
            </Box>

          }
          {checkLocation('/home') &&
            <Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {streamPages.map((page) => (
                  <Button
                    key={page}
                    value={page}
                    onClick={e => navigateToPage(page)}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="navigation"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ p: 0 }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {streamPages.map((page) => (
                    <MenuItem key={page} value={page} onClick={e => navigateToPage(page)}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              
              {/* <img alt='atlas_logo' src={atlas_logo} style={{ fill: 'white', stroke: 'white' }}/> */}
            </Box>

          }
          <Box sx={{ flexGrow: 0, ml: 4 }}>
                <IconButton
                  size="large"
                  aria-label="navigation"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenAppMenu}
                  color="inherit"
                  sx={{ p: 0 }}
                >
                  <AppsIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElApp}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElApp)}
                  onClose={handleCloseAppMenu}
                  sx={{
                    display: { xs: 'block' },
                  }}
                >
                  {applications.map((app) => (
                    <MenuItem key={app} value={app} onClick={e => navigateToApp(app)}>
                      <Typography textAlign="center">{app}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>


        </Toolbar>
      </Container>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
    </AppBar>
  );
};

ResponsiveAppBar.propTypes = {
  setRole: PropTypes.func,
  signOut: PropTypes.func,
  user: PropTypes.object
}

export default ResponsiveAppBar;
