import { MISSING_DATE, PAT_DATE, SCRAP_DATE } from "../DynamoDbConstants"

export function unixToAppDate (unix) {
  let iso = unix
  // if (typeof iso === 'number') {
  //   iso = iso * 1000
  // }
  const date = new Date(iso).toLocaleDateString('en-gb', { day: "2-digit", month: "2-digit", year:"2-digit" })
  return date
}

export function unixToMessageDate (unix) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric'
  }

  const date = new Date(unix).toLocaleString('en-gb', options)
  return date
}

export function datePlusYear (date) {
  const appDate = new Date((date))
  appDate.setFullYear(appDate.getFullYear() + 1)
  return appDate
}

export const getExpiryDate = (date) => {
  if (date !== 0) {
    return unixToAppDate(datePlusYear(date))
  }
}

export function dateNowAsUnix () {
  return Date.now()
}

export function dateNowPlusYearAsUnix () {
  var date = new Date()
  date.setFullYear(date.getFullYear() + 1)
  return date.getTime()
}

export function dateNowMinusYearAsUnix () {
  var date = new Date()
  date.setFullYear(date.getFullYear() - 1)
  return date.getTime()
}

export function checkApplianceWarningOrExpired (appliance) {
  if ((!appliance[SCRAP_DATE] && !appliance[MISSING_DATE]) && (checkApplianceExpired(appliance) || checkApplianceWarning(appliance))) {
    return true
  }
  return false
}

export function checkApplianceExpired (appliance) {
  if (appliance !== undefined && datePlusYear(appliance[PAT_DATE]) < (new Date())) {
    return true
  }
  return false
}

export function checkApplianceWarning (appliance) {
  const now = new Date()
  var monthFromNow = new Date()
  monthFromNow.setDate(monthFromNow.getDate() + 30)
  const patDate = datePlusYear(appliance[PAT_DATE])
  if (appliance !== undefined && patDate < monthFromNow && patDate > now) {
    return true
  }
  return false
}


export function checkAppliancesForExpiry (appliances) {
  if (appliances !== undefined && appliances.find(element => element.date < (dateNowAsUnix())) !== undefined) {
    return true
  }
  return false
}

export function checkAuditDate (date) {
  const now = new Date()
  var monthAgo = new Date()
  monthAgo.setDate(monthAgo.getDate() - 30)
  const auditDate = new Date((date))
  if (auditDate < now && auditDate > monthAgo ) {
    return true
  }
  return false
}

export function getDateTextStyle (date, app) {
  if (app[MISSING_DATE] || app[SCRAP_DATE]) {
    return {
      color: 'grey'
    }
  }
  const now = new Date()
  var monthFromNow = new Date()
  monthFromNow.setDate(monthFromNow.getDate() + 30)
  const appDate = new Date((date))
  appDate.setFullYear(appDate.getFullYear() + 1)
  if (appDate < monthFromNow && appDate > now) {
    return {
      color: 'orange'
    }
  } else if (appDate < now) {
    return {
      color: 'red'
    }
  } else {
    return {
      color: 'green'
    }
  }
}