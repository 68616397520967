import * as AWS from 'aws-sdk'

const configuration = {
  region: 'eu-west-2',
  secretAccessKey: 'A59d5KqU3Gz4vUzJcfPauHgrOQoOsuaiM0RSNtqy',
  accessKeyId: 'AKIAZ7FYJQRGB7NT6FKX'
}

const cognitoProvider = new AWS.CognitoIdentityServiceProvider(configuration)
const USERPOOLID = 'eu-west-2_xGH8EhAxS'

export const createUser = (email, ccId) => {
  
  const params = {
    UserPoolId: USERPOOLID,
    Username: email,
    UserAttributes: [{
      Name: "email",
      Value: email,
    },
    {
      Name: "email_verified",
      Value: "true"
    },
    {
      Name: "custom:cc_id",
      Value: ccId
    },
  ],
  TemporaryPassword: Math.random().toString(36).substr(2, 10),
  // use below if you don't want invite email to be sent
  // DesiredDeliveryMediums: [],
  // MessageAction: "SUPPRESS"
  }

  return cognitoProvider.adminCreateUser(params).promise()
}

export const deleteUser = (userId) => {
  const params = {
    UserPoolId: USERPOOLID,
    Username: userId
  }

  return cognitoProvider.adminDeleteUser(params).promise()
}